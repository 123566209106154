<script setup>
const goBack = useGoBackDebounced();

const {
  handleClick: pressButton,
  isPressedDown: isButtonPressed,
  setPressed: setButtonPressed,
  unsetPressed: unsetButtonPressed
} = useButton(goBack);
</script>

<template>
  <div
    class="navigation-back"
    :class="{ 'pressed-kiosk-card': isButtonPressed }"
    @mousedown="setButtonPressed"
    @touchstart.prevent="setButtonPressed"
    @mouseup="unsetButtonPressed"
    @mouseleave="unsetButtonPressed"
    @touchend="pressButton"
    @click="pressButton"
  />
</template>

<style scoped>
.navigation-back {
  position: absolute;
  bottom: 9%;
  left: 0.25%;
  width: 7.15%;
  height: 12%;
  background-image: url("@/assets/graphics/icons/back-to-cards.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.pressed-kiosk-card {
  transform: scale(0.98);
}
</style>
