<script setup>
import careDayWeiss3 from "assets/graphics/img/careday-weiss.svg";

const emit = defineEmits(["logoClick"]);

const LONG_PRESS_TIME_MS = 500;

let checkTime = null;

const handlePressStart = () => {
  checkTime = setTimeout(() => {
    checkPress();
  }, LONG_PRESS_TIME_MS);
};

const handlePressEnd = () => {
  clearTimeout(checkTime);
  checkTime = null;
};

const checkPress = () => {
  if (checkTime) {
    emitLogoClickEvent();
    checkTime = null;
  }
};

const emitLogoClickEvent = () => {
  emit("logoClick");
};
</script>

<template>
  <div class="info-logo centered cb_remove_on_mobile">
    <img :src="careDayWeiss3" alt="" @touchstart="handlePressStart" @touchend="handlePressEnd">
  </div>
</template>

<style scoped>
.info-logo {
  grid-area: Logo;
  width: 75%;
  justify-self: flex-end;
  padding-top: 5%;
  padding-right: 3%;
}

img{
  width: 100%;
  height: auto;
}
</style>
