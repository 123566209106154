<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const props = defineProps({
  pin: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["confirmPin", "closePinPad"]);

const pinInput = ref("");
const hidePin = ref(true);
const pinError = ref(false);

const pressNumber = (number) => {
  if (pinInput.value.length < 4) {
    pinInput.value += number;
  }
};

const pressBackspace = () => {
  if (pinInput.value.length > 0) {
    pinInput.value = pinInput.value.substring(0, pinInput.value.length - 1);
  }
};

const checkPin = () => {
  if (pinInput.value === props.pin) {
    pinError.value = false;
    confirmPin();
  } else {
    pinError.value = true;
    resetPin();
  }
};

const confirmPin = () => {
  emit("confirmPin");
  resetPin();
};

const closePinPad = () => {
  emit("closePinPad");
  resetPin();
};

const resetPin = () => {
  pinInput.value = "";
};

const togglePinVisibility = () => {
  hidePin.value = !hidePin.value;
};

const pinOutput = computed(() => {
  if (hidePin.value) {
    return pinInput.value.replace(/./g, "*");
  } else {
    return pinInput.value;
  }
});

// parameters are 1-indexed
const calculateDigit = (row, col) => col + 3 * (row - 1);
</script>

<template>
  <div class="pin-modal">
    <div class="pin-modal-content">
      <div class="pin-modal-header">
        <h3 class="pin-header-title">
          {{ $t("pinpad.pin_required") }}
        </h3>
        <div class="pin-header-button">
          <font-awesome-icon icon="fa-circle-xmark" @touchend="closePinPad" />
        </div>
      </div>
      <div class="pin-modal-body">
        <div v-show="pinError" class="pin-input-error-msg">
          {{ $t("pinpad.wrong_pin") }}
        </div>

        <div class="input-row" style="position: relative">
          <div class="input-group input-wrapper">
            <input
              v-model="pinOutput"
              type="text"
              class="pin-input"
              :class="{ 'pin-input-error': pinError }"
              placeholder="PIN"
              aria-describedby="showPasswordIcon"
              readonly
            />
            <span
              id="showPasswordIcon"
              class="input-group-text pin-input-icon-container"
              :class="{ 'pin-input-icon-container-error': pinError }"
              @touchend="togglePinVisibility"
            >
              <font-awesome-icon class="pin-input-icon" icon="fa-eye" />
            </span>
          </div>
        </div>
        <div class="numpad-row">
          <div v-for="i in 3" :key="i" class="digits-row">
            <div
              v-for="j in 3"
              :key="j"
              class="digit"
              @touchend="pressNumber(calculateDigit(i, j))"
            >
              {{ calculateDigit(i, j) }}
            </div>
          </div>
          <div class="digits-row">
            <div class="digit">0</div>
            <div class="digit backspace" @touchend="pressBackspace">
              <font-awesome-icon icon="fa-delete-left" class="backspace-icon" />
            </div>
          </div>
        </div>
      </div>
      <div class="pin-modal-footer" @touchend="checkPin">
        <h3 class="pin-modal-title pin-modal-button-text">
          {{ $t("pinpad.confirm") }}
        </h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pin-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;

  --modal-border-radius: 2em;
  --modal-horizontal-padding: 100px;
  --input-border-radius: 45px;
}

.pin-modal-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #f5f7fc;
  border-radius: 90px;
  height: 80vh;
  width: 30vw;
}

.pin-modal-header {
  flex: 1;

  border-bottom: none;
  background-color: #87bbb9;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
}

.pin-header-title {
  flex: 9;
  font-family: var(--ct_font_bold);
  font-style: normal;
  font-size: 3em;
  margin: 0;
  color: var(--ct_A);
}

.pin-header-button {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 1em;
  font-size: 2.5em;
}

.pin-modal-body {
  position: relative;
  display: flex;
  flex: 8;
  flex-direction: column;

  padding: 0 var(--modal-horizontal-padding);
}

.pin-input-error-msg {
  position: absolute;
  top: 0;
  left: 15%;
  height: 5%;
  background-color: #f56262;
  border-radius: 15px;
  z-index: 10;
  font-size: 1.7em;
  padding: 0.1em 0.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.input-row {
  flex: 1;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  height: 70% !important;
  width: 100%;
}
.pin-input {
  flex: 8;
  height:100%!important;
  max-width: 80%;
  margin-left: 0.4em;
  padding-left: 1em;
  background-color: #e3ecee !important;
  border-top-left-radius: var(--input-border-radius);
  border-bottom-left-radius: var(--input-border-radius);
  font-size: 3rem !important;
}

.pin-input::placeholder {
  font-size: 3rem !important;
  line-height: 0!important;
}

.pin-input-error {
  border-left: solid 4px #f56262 !important;
  border-top: solid 4px #f56262 !important;
  border-bottom: solid 4px #f56262 !important;
}

.pin-input-icon-container {
  background-color: #c8dadd80;
  flex: 2;
  height: 101% !important;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}

.pin-input-icon-container-error {
  border-right: solid 4px #f56262 !important;
  border-top: solid 4px #f56262 !important;
  border-bottom: solid 4px #f56262 !important;
}

.pin-input-icon {
  font-size: 2em;
}

.numpad-row {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.digits-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
}

.digit {
  flex: 1;
  background-color: #9fc7c7;
  margin: 0.2em;
  border-radius: var(--input-border-radius);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.backspace {
  flex: 2;
  padding: 0 0.2em 0 0.2em;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.backspace-icon {
  font-size: 1.8em;
}

.pin-modal-footer {
  flex: 1;
  background-color: #f2d028;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-bottom-left-radius: var(--modal-border-radius);
  border-bottom-right-radius: var(--modal-border-radius);

  cursor: pointer;

  margin-top: 1em;
}

.pin-modal-button-text {
  font-size: 2em;
}
</style>
